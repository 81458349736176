.App {
  text-align: center;
}

.floating-columns{
  margin-top: -4%;
}

.floating-cards{
  background-color: #6680A6 !important;
  color: #ffffff;
  margin-top: 10px;
}

@media screen and (max-width: 767px){
  .floating-columns{
    margin-top: 0;
  }

  .login-form-image{
    display: none;
  }


  .login-form{
    margin: 0 auto;
  }

  .center-vert {
    /* margin: 0; */
    position:relative !important;
    margin: 10% 10% !important;
    transform: none !important;
    top: auto !important;
    left: auto !important;
  }
}

.page-body{
  padding: 40px 0 0 0 !important;
}

.mini-statement-section{
  background-color: #2553AD;
  color: #ffffff;
}

.alt-section{
  background-color: #EFEFEF;
}

.card-image{
  width: 60%;
}

.card-row{
  padding: 50px 0;
}

.card-text{
  padding: 50px 0
}

.testimonial-slider{
  padding: 40px 0;
  height: 50% !important;
}

.testimonial-mini-heading {
  color: #6680A6;
}

.testimonial-heading {
  color: #2553AD;
  font-weight: bold;
}

.testimonial-paragraph {
  font-style: italic;
}

.testimonial-person {
  font-weight: bold;
}

.contact-us-section{
  height: auto;
  background-image: url('images/around-the-world.jpg');
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(18, 18, 18, 0.7);
  padding: 40px 0;
  color:#ffffff
}

.contact-form-area{
  width: 50%;
  text-align: center;
  margin: 0 auto;
}
.contact-form{
  width: 80%;
  margin: 0 auto;
}

.home-form input[type="text"] {
  background-color: rgba(255, 255, 255, 0.75) !important;
  border-radius: 15px;
  height: 50px ;
  padding: 20px !important;
}

.home-form input[type="email"] {
  background-color: rgba(255, 255, 255, 0.75) !important;
  border-radius: 15px;
  height: 50px;
  padding: 20px !important;
}

.home-form textarea{
  background-color: rgba(255, 255, 255, 0.75) !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
  height: 120px;
  padding: 20px !important;
}

.home-form label{
  color: #2553AD !important;
}

.contact-form input[type="text"] {
  background-color: rgba(255, 255, 255, 0.75) !important;
  border-radius: 15px;
  height: 50px ;
  padding: 20px !important;
}

.contact-form  input[type="email"] {
  background-color: rgba(255, 255, 255, 0.75) !important;
  border-radius: 15px;
  height: 50px;
  padding: 20px !important;
}

.contact-form  textarea{
  background-color: rgba(255, 255, 255, 0.75) !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
  height: 120px;
  padding: 20px !important;
}

.contact-form  label{
  color: #2553AD !important;
}

.footer-bg{
  background-color: #0a4275;
}

.footer-alt-bg{
  background-color: rgba(0, 0, 0, 0.2)
}

.blue-background{
  background-color: #2553AD;
  padding: 2% 15%;
  color: #ffffff
}

.blue-background-section{
  background-color: #2553AD;
  padding: 40px 0;
  color: #ffffff
}

.red-background{
  background-color: #DE3163;
  padding: 2% 15%;
  color: #ffffff
}
.red-background-section{
  background-color: #DE3163;
  padding: 40px 0;
  color: #ffffff
}

.candidate-cards{
  padding: 30px 0;
}

.card-text{
  padding: 0;
}

.contact-form-heading{
  padding-top: 15%;
  padding-bottom: 10%;
  color: #ffffff
}

.center-vert {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.candidate-signup-form input[type="text"] {
  background-color: #ffffff !important;
  border-radius: 15px;
  height: 50px ;
  padding: 20px !important;
}

.candidate-signup-form input[type="email"] {
  background-color: #ffffff !important;
  border-radius: 15px;
  height: 50px ;
  padding: 20px !important;
}

.candidate-signup-form input[type="select"] {
  background-color: #ffffff !important;
  border-radius: 15px;
  height: 50px ;
  padding: 20px !important;
}
.candidate-signup-form input[type="password"], .webkit input[type="password"] {
  background-color: #ffffff !important;
  border-radius: 15px;
  height: 50px ;
  padding: 20px !important;
}

.main-bg {
  background-image: url('./images/around-the-world.jpg');
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
  background-size: cover;
  height: 100vh;
}

.main-heading {
  color: #FFFFFF;
}

.main-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-margin{
  margin-top: 300px !important;
}

@media (max-width: 450px) {

  .main-margin{
    margin-top: 50px !important;
  }

  .main-row {
    display: inline-block;
    justify-content: center;
    align-items: center;
  }

  .main-bg {
    background-image: url('./images/around-the-world.jpg');
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
    background-size: cover;
    height: max-content;
  }

  .main-heading {
    color: #FFFFFF;
    font-size: 20px;
  }
}

.splash-card-heading{
  color: #2553AD
}

.splash-card-line{
  width: 50%;
  margin: 0 auto;
}

.splash-card-paragraph {
  padding-top: 10px;
  text-align: center;
}

.splash-card-footer {
  text-align: center;
}

.splash-card-footer button{
  width: 50%;
  margin: 0 auto;
  background-color: #DE3064;

}

.modal-height{
  height: 80vh;
}

.full-page{
  height: 100vh;
}

.logo-image{
  width: 100%
}

@media screen and (max-width: 767px){
  .logo-image{
    width: 60%
  }
}

/* Countdown Timer */

.expired-notice {
  text-align: center;
  padding: 2rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  /* border: 1px solid #ebebeb;
  border-radius: 0.25rem; */
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #fff;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.countdown-link {
  cursor: auto !important; /* Set cursor style to auto, overriding any other styles */
}

/* #fifth {
  background: rgb(5, 134, 219);
  padding: 40px 40px;
}

#fifth div {
  display: flex;
  justify-content: space-around;
}

#fifth a {
  display: flex;
  justify-content: space-around;
  color: white;
  text-decoration: none;
  margin: 0px 1%;
}

#fifth img {
  margin-right: 7%;
}

#fifth h1 {
  text-align: center;
  padding-bottom: 5%;
  color: #fff;
} */